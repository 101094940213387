<template>
  <div>
    <b-row>
      <b-col
        lg="4"
        class="text-center mb-2"
      >
        <validation-provider
          v-slot="{ errors }"
          vid="form_data.client.pic"
          name="logo"
        >
          <ImageUploader
            :image="form.form_data.image_path"
            @set-image="handleSetImage"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-col>
      <b-col
        lg="8"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Type of Market"
            >
              <MySelectTypeOfMarket
                :market="form.form_data.client.type"
                :vid-name="'form_data.client.type'"
                @set-selected-market="handleSetSelectedMarket"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Phone Number"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.phone"
                name="Phone Number"
              >
                <b-form-input
                  v-model="form.form_data.address.phone"
                  placeholder="Enter Phone Number"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Main Name"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.client.name"
                name="Main Name"
              >
                <b-form-input
                  v-model="form.form_data.client.name"
                  placeholder="Enter Main Name (e.g. MyDin)"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Outlet Branch Name"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.name"
                name="Outlet Branch Name"
              >
                <b-form-input
                  v-model="form.form_data.address.name"
                  placeholder="Enter Outlet Branch Name"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Unit Number"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.unit"
                name="Unit Number"
              >
                <b-form-input
                  v-model="form.form_data.address.unit"
                  placeholder="Enter Unit Number"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group
              label="Address Line 1"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.address1"
                name="Address Line 1"
              >
                <b-form-input
                  v-model="form.form_data.address.address1"
                  placeholder="Enter Address Line 1"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Address Line 2"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.address2"
                name="Address Line 2"
              >
                <b-form-input
                  v-model="form.form_data.address.address2"
                  placeholder="Enter Address Line 2"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Address Line 3 (Optional)"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.address3"
                name="Address Line 3"
              >
                <b-form-input
                  v-model="form.form_data.address.address3"
                  placeholder="Enter Address Line 3"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Postcode"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.postcode"
                name="Postcode"
              >
                <b-form-input
                  v-model="form.form_data.address.postcode"
                  placeholder="Enter Postcode"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Area"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="form_data.address.area"
                name="Area"
              >
                <b-form-input
                  v-model="form.form_data.address.area"
                  placeholder="Enter Area"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="State"
            >
              <MySelectStates
                :state="form.form_data.address.state"
                :vid-name="'form_data.address.state'"
                @set-selected-state="handleSetSelectedState"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Country"
            >
              <MySelectCountries
                :country="form.form_data.address.country"
                :vid-name="'form_data.address.country'"
                @set-selected-country="handleSetSelectedCountry"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader.vue'
import { ValidationProvider } from 'vee-validate'
import MySelectTypeOfMarket from '@/components/MySelectTypeOfMarket.vue'
import MySelectStates from '@/components/MySelectStates.vue'
import MySelectCountries from '@/components/MySelectCountries.vue'

export default {
  name: 'AddressEntryInquiry',
  components: {
    ImageUploader,
    MySelectTypeOfMarket,
    MySelectStates,
    MySelectCountries,
    ValidationProvider,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleSetImage(data) {
      this.form.form_data.client.pic = data.file_name
      this.form.form_data.image_path = data.image_path
    },
    handleSetSelectedMarket(value) {
      this.form.form_data.client.type = value
    },
    handleSetSelectedCountry(value) {
      this.form.form_data.address.country = value
    },
    handleSetSelectedState(value) {
      this.form.form_data.address.state = value
    },
  },
}
</script>
