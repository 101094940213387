<template>
  <div>
    <my-table
      title=""
      :rows="rows"
      :columns="columns"
      :date-range-enabled="true"
      :date-range-field="'date_added'"
      :pagination="pagination"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'date_added'">
          {{ $helpers.formatDate(props.row.date_added) }}
        </span>
        <span v-else-if="props.column.field === 'user.name'">
          {{ props.row.user.name }}
        </span>

        <span v-else-if="props.column.field === 'status.text'">
          <b-badge
            :variant="props.row.status.variant"
            class="p-1 text-white font-weight-bold w-100"
          >
            {{ props.row.status.text }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'type.title'">
          {{ props.row.type.title }}
        </span>
        <span v-else-if="props.column.field === 'action'">
          <b-button
            variant="primary"
            @click.prevent="handleReviewInquiry(props.row)"
          >Review Inquiry</b-button>
        </span>
        <span v-else>
          {{ props.row[props.column.field] }}
        </span>
      </template>
    </my-table>
    <AddInquiryReviewModal
      :form-data="selectedInquiryFormData"
      :is-show="showInquiryModal"
      :selected-inquiry="selectedInquiry"
      @reset-modal="handleResetModal"
      @refetch-inquiries="fetchInquiries"
    />
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import AddInquiryReviewModal from '@/components/AddInquiryReviewModal.vue'
import axios from '@axios'

export default {
  components: {
    MyTable,
    AddInquiryReviewModal,
  },
  data() {
    return {
      isLoading: false,
      showInquiryModal: false,
      columns: [
        {
          label: 'Date Inquired',
          field: 'date_added',
          thClass: 'text-nowrap align-middle text-left',
          tdClass: 'align-middle text-left',
        },
        {
          label: 'Requester Name',
          field: 'user.name',
          thClass: 'text-nowrap align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Inquiries',
          field: 'type.title',
          thClass: 'text-nowrap align-middle',
          tdClass: 'align-middle',
        },
        {
          field: 'status.text',
          label: 'Status',
          thClass: 'align-middle',
          tdClass: 'w-10',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'text-nowrap align-middle',
          tdClass: 'text-center align-middle w-12',
          sortable: false,
        },
      ],
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 5,
        perPageDropdown: [5, 10, 15],
      },
      rows: [],
      selectedInquiry: null,
      selectedInquiryFormData: null,
    }
  },
  mounted() {
    this.fetchInquiries()
  },
  methods: {
    async fetchInquiries() {
      this.isLoading = true
      const response = await axios.get('/inquiries?type=admin')
      if (response.status === 200) {
        this.rows = response.data
      }
      this.isLoading = false
    },
    handleReviewInquiry(row) {
      this.selectedInquiry = row.type.title
      this.selectedInquiryFormData = row
      this.showInquiryModal = true
    },
    handleResetModal() {
      this.showInquiryModal = false
    },
    handleSuccessReview() {
      this.showInquiryModal = false
      this.fetchInquiries()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
