<template>
  <b-modal
    id="inquiry-modal"
    ref="inquiry-modal"
    v-model="show"
    :title="title"
    size="xl"
    hide-footer
    no-close-on-backdrop
    centered
    @hidden="handleHiddenModal"
  >
    <b-container
      fluid
      class="py-3"
    >
      <ValidationObserver
        ref="inquiryForm"
        v-slot="{ passes }"
      >
        <form @submit.prevent="passes(handleSubmitReview)">
          <b-row>
            <b-col md="12">
              <div v-if="selectedInquiry === 'Address Entry Request'">
                <AddressEntryRequestForm
                  :form="formData"
                />
              </div>
              <hr>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
              class="d-flex flex-row align-items-center justify-content-end"
            >
              <div class="w-40">
                <b-form-group
                  label="Add Review"
                  class="mt-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="status"
                    name="review"
                  >
                    <v-select
                      v-model="selectedReviewAction"
                      :options="reviewActions"
                      :clearable="false"
                      label="text"
                      :reduce="reviewAction => reviewAction.value"
                      @input="handleSetSelectedReview"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="showRemarksTextArea"
                  label=""
                  class="mt-1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="remarks"
                    name="remarks"
                  >
                    <b-form-textarea
                      id="remarks"
                      v-model="formData.remarks"
                      placeholder="Add Remarks..."
                      rows="3"
                      max-rows="6"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <b-button
            variant="primary"
            class="float-right my-2"
            type="submit"
            :disabled="isLoading"
          >
            <b-spinner
              v-if="isLoading"
              class="mr-50 p-0"
              small
            />
            <feather-icon
              v-else
              icon="SaveIcon"
              class="mr-50"
            />
            Save And Apply Changes
          </b-button>
        </form>
      </ValidationObserver>
    </b-container>
  </b-modal>
</template>

<script>
import AddressEntryRequestForm from '@/components/AddressEntryRequestForm.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  name: 'AddInquiryReviewModal',
  components: {
    AddressEntryRequestForm,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    selectedInquiry: {
      type: String,
      default: null,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Review Inquiry Details',
    },
  },
  data() {
    return {
      show: false,
      isLoading: false,
      reviewActions: [
        { value: 1, text: 'Approved' },
        { value: 2, text: 'Re-Edit' },
        { value: 3, text: 'Declined' },
      ],
      selectedReviewAction: null,
    }
  },
  computed: {
    showRemarksTextArea() {
      return this.selectedReviewAction === 2 || this.selectedReviewAction === 3
    },
  },
  watch: {
    isShow(newValue) {
      this.show = newValue
    },
  },
  methods: {
    handleHiddenModal() {
      this.show = false
      this.selectedReviewAction = null
      this.$emit('reset-modal')
    },
    async handleSubmitReview() {
      try {
        this.isLoading = true
        const form = { ...this.formData, ...{ status: this.selectedReviewAction } }
        const response = await axios.patch(`/inquiries-review/${this.formData.id}`, form)
        if (response.status === 422) {
          this.$refs.inquiryForm.setErrors(response.data.errors)
          this.isLoading = false
          return
        }
        this.isLoading = false
        this.$emit('close-modal')
        this.$swal({
          icon: 'success',
          title: 'Review Added',
          text: 'Successfully Reviewed Inquiry',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        }).then(() => {
          this.$emit('refetch-inquiries')
          this.handleHiddenModal()
        })
      } catch (error) {
        throw new Error('Something went wrong.', error)
      }
    },
    handleSetSelectedReview() {
      this.formData.status = this.selectedReviewAction
    },
  },
}
</script>
